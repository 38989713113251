* {
  box-sizing: border-box;
}
html,
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
button,
input,
textarea,
p,
select,
blockquote,
th,
td,
img
{
  margin: 0;
  padding: 0;
  font-weight: 300;
  color: #000;
  font-size: 1em;
  word-break: keep-all;
  word-wrap: break-word;
}
html,
body {
  font-size: 16px;
  width: 100%;
  height: 100% !important;
  overflow-x: hidden;
  overscroll-behavior: contain;
}
body.active {
  overflow-y: hidden;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
  color: #000;
}
a:hover {
  text-decoration: none;
}
a:active {
  text-decoration: none;
}
table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}
img {
  display: block;
  border: 0;
}
button {
  background: transparent;
  border: 0;
  cursor: pointer;
  outline: 0;
}
input,
select,
textarea {
  border: 1px solid #aaa;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  outline: none;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.ant-upload-list-item-actions > a {
  display: none;
}
.ant-pagination-options {
  display: none;
}
.ant-result > .ant-result-icon {
  display: none;
}
.ant-descriptions-title {
  flex: none;
}

.ant-result {
  padding: 5px 400px 0 400px;
}

.ant-result-title {
  text-align: left;
}

@media (max-width: 1023px) {
  .ant-result {
    padding: 0px;
  }
}
@media print {
  html, body { padding: 0px; zoom: 0.75 }
}
